import { FacebookBlackIcon, InsBlackIcon, LinkedinBlackIcon, XBlackIcon, YoutubeBlackIcon } from '../../icons/icons';
import { RelLink } from '../../rel-link';
import { useContext } from 'react';
import { DeviceContext } from '@/src/store/contexts/device-context';

export const SocialLinks = ({ children, className, ...props }: any) => {
  const { isMobile } = useContext(DeviceContext);
  const v = isMobile ? 48 : 32;
  return (
    <div
      className="flex w-full flex-col items-center gap-4 border-b border-t border-black300 py-6 pr-4 md:w-auto  md:items-start md:gap-2 md:border-0 md:py-0 md:pl-[64px]  md:pr-0"
      data-testid="socialLinks"
    >
      <div className="whitespace-nowrap pb-3 text-sm font-semibold md:text-base">Bizi takip edin</div>
      <div className="flex w-full flex-1 flex-row justify-between gap-2 ">
        <RelLink
          rel="noopener noreferrer"
          aria-label="Hangikredi Facebook"
          href={'https://www.facebook.com/hangikredi'}
          data-testid="facebookLink"
        >
          <FacebookBlackIcon width={v} height={v} data-testid="facebookIcon" />
        </RelLink>
        <RelLink
          rel="noopener noreferrer"
          aria-label="Hangikredi Youtube"
          href={'https://www.youtube.com/user/HangiKredi'}
          data-testid="youtubeLink"
        >
          <YoutubeBlackIcon width={v} height={v} data-testid="youtubeIcon" />
        </RelLink>
        <RelLink
          rel="noopener noreferrer"
          aria-label="Hangikredi Twitter"
          href={'https://twitter.com/hangikredicom'}
          data-testid="XLink"
        >
          <XBlackIcon width={v} height={v} data-testid="XLink" />
        </RelLink>
        <RelLink
          rel="noopener noreferrer"
          aria-label="Hangikredi Instagram"
          href={'https://www.instagram.com/hangikredi/'}
          data-testid="instagramLink"
        >
          <InsBlackIcon width={v} height={v} data-testid="instagramIcon" />
        </RelLink>
        <RelLink
          rel="noopener noreferrer"
          aria-label="Hangikredi LinkedIn"
          href={'https://www.linkedin.com/company/hangikredi.com/'}
          data-testid="linkedinLink"
        >
          <LinkedinBlackIcon width={v} height={v} data-testid="linkedinIcon" />
        </RelLink>
      </div>
    </div>
  );
};
