'use client';

import Footer from '@/src/features/ui/footer';
import FooterSimple from '@/src/features/ui/footer/footer-simple';
import { OriginalLocationContext } from '@/src/store/contexts/original-location-context';
import { usePathname } from 'next/navigation';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import FullSkeleton from '../features/ui/full-skeleton/full-skeleton';
import Header from '../features/ui/header';
import { UserAgent } from '../interfaces/missing-types';
import { DeviceContext } from '../store/contexts/device-context';
import { ResponseMenuItemList } from '@/src/interfaces/data-contracts';
import { Logo } from '../features/ui/icons/icons';
import Link from 'next/link';
import { setCookie } from 'cookies-next';

type Props = {
  children: React.ReactNode;
  userAgent: UserAgent;
  menuList: ResponseMenuItemList;
};

export const LayoutClient: React.FC<Props> = ({ children, userAgent, menuList }) => {
  const pathname = usePathname() ?? '';
  const [originalUrl, setOriginalUrl] = useState<string | null>(
    (typeof window !== 'undefined' && window.location.href) || null
  );
  const isBankIntegration = pathname.includes('int/basvuru/form');
  const isPosWizard = pathname.includes('/sihirbaz/form');
  const containsRedirect = pathname.includes('/yonlendirme') && !pathname.includes('success/yonlendirme');
  const { headerItems, hamburgerItems, footerItems } = menuList;
  const containsLoginorForgotPassword =
    pathname?.includes('/giris') || pathname?.includes('/parola-unuttum') || pathname?.includes('/parola-guncelle');

  useEffect(() => {
    window.scrollTo(0, 0);
    setCookie('referrer', window.location.href);
  }, [pathname]);

  useEffect(() => {
    if (!originalUrl) {
      setOriginalUrl(window.location.href);
    }
  }, []);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker
          .register('/assets/relatedpush_sw.js')
          .then((_registration) => {})
          .catch((error) => {
            console.warn('Service worker relatedpush registration failed:', error);
          });
      });
    }
  }, []);

  const memoizedOriginalUrl = useMemo(() => ({ originalUrl }), [originalUrl]);

  if (containsRedirect && !isBankIntegration) {
    return (
      <DeviceContext.Provider value={userAgent}>
        <OriginalLocationContext.Provider value={memoizedOriginalUrl}>
          {/* footerin ekranin sonuna inmesi isteniyorsa main flex-1 verilmeli */}
          <main className={`block w-full ${isBankIntegration ? 'flex-1' : ''}`}>{children}</main>
          <Suspense fallback={<FullSkeleton />}>
            {/*<DynamicFooterSimple />*/}
            <FooterSimple />
          </Suspense>
        </OriginalLocationContext.Provider>
      </DeviceContext.Provider>
    );
  }
  if (containsLoginorForgotPassword) {
    return (
      <DeviceContext.Provider value={userAgent}>
        <OriginalLocationContext.Provider value={memoizedOriginalUrl}>
          <div className="mt-3 flex flex-col items-center gap-9 md:mt-[42px] md:gap-[46px]">
            <Link href={'/'} data-testid="logoLink">
              <Logo width={127} height={36} data-testid="logo" />
            </Link>
            <main className="flex w-full items-center justify-center">{children}</main>
          </div>
        </OriginalLocationContext.Provider>
      </DeviceContext.Provider>
    );
  }
  return (
    <DeviceContext.Provider value={userAgent}>
      <OriginalLocationContext.Provider value={memoizedOriginalUrl}>
        <Suspense fallback={<FullSkeleton />}>
          {!(isBankIntegration && containsRedirect) && headerItems && hamburgerItems && !isPosWizard && (
            <Header headerItems={headerItems} hamburgerItems={hamburgerItems} />
          )}
        </Suspense>
        <main className="block w-full flex-1">{children}</main>
        <Suspense fallback={<FullSkeleton />}>
          {/*<DynamicFooter />*/}
          {footerItems && !isPosWizard && <Footer footerItems={footerItems} hideTop={isBankIntegration} />}
        </Suspense>
      </OriginalLocationContext.Provider>
    </DeviceContext.Provider>
  );
};
