import React, { Fragment, useContext } from 'react';
import { DeviceContext } from '@/src/store/contexts/device-context';
import { InnerContainer } from '../../common/inner-container';
import { RelLink } from '../../rel-link';
import { Links } from './links';
import { SocialLinks } from './social-links';
import { ResponseMenuItem } from '@/src/interfaces/data-contracts';
import classNames from 'classnames';
import { AngledArrowUpIcon } from '@/src/features/ui/icons/icons';

type FAQItem = {
  name: string;
  explain: string;
  url: string;
};
type Props = {
  children?: React.ReactNode;
  className?: string;
  footerItems?: ResponseMenuItem[];
};
export const Top: React.FC<Props> = ({ children, className, footerItems, ...props }) => {
  const { isMobile } = useContext(DeviceContext);
  return (
    <InnerContainer data-testid="footerTop">
      <div className="flex flex-col pb-[4px]  md:justify-between md:pb-8 " {...props}>
        {isMobile ? (
          <div className="footer-wrap">
            <ul className="flex flex-col" data-testid="footerTopList">
              {footerItems?.map((item: ResponseMenuItem, index: number) => {
                const randomId = `${item.id}-${index}`;
                return (
                  <Fragment key={randomId}>
                    {item.subMenuItemList && item.subMenuItemList?.length > 0 ? (
                      <li className="" data-testid="footerTopItem">
                        <input
                          type="checkbox"
                          name={'footer-check'}
                          id={randomId}
                          className={classNames('footer-acc-input hidden w-full')}
                        />
                        <div className={classNames('footer-acc-content')}>
                          <label
                            htmlFor={randomId}
                            className={classNames(
                              'label flex w-full items-center justify-between  pb-3 font-semibold text-black900',
                              item.badgeType
                            )}
                          >
                            {item.name}
                            <AngledArrowUpIcon
                              width={20}
                              height={20}
                              className={classNames(
                                item.badgeType,
                                'arrow rotate-180 text-black1000 transition-all duration-300'
                              )}
                            />
                          </label>
                          <span>
                            <ul className="flex flex-col gap-5 pl-3">
                              {item.subMenuItemList.map((child: ResponseMenuItem, idx: number) => {
                                return (
                                  <li key={child.url! + idx} className="text-[14px] text-black700">
                                    {child.url !== 'null' ? (
                                      <RelLink href={child.url!}>{child.name}</RelLink>
                                    ) : (
                                      child.name
                                    )}
                                  </li>
                                );
                              })}
                            </ul>
                          </span>
                        </div>
                      </li>
                    ) : (
                      <Fragment key={randomId}>
                        <li className="py-4 text-base font-semibold text-black900" data-testid="footerTopItem">
                          {item.url !== 'null' ? <RelLink href={item.url!}>{item.name}</RelLink> : item.name}
                        </li>
                      </Fragment>
                    )}
                  </Fragment>
                );
              })}
            </ul>
            <div className="flex flex-col items-center md:py-5">
              {/*<div className="pr-4">*/}
              <SocialLinks />
              {/*</div>*/}
            </div>
          </div>
        ) : (
          <div className="flex flex-1 flex-col  justify-between gap-6 md:flex-row">
            <Links footerItems={footerItems} className="" />
            <SocialLinks />
          </div>
        )}
      </div>
    </InnerContainer>
  );
};
