'use client';

import { DeviceContext } from '@/src/store/contexts/device-context';
import React, { useContext, useRef } from 'react';
import { HeaderDesktop } from './desktop/desktop';
import MenuItemsApi from './header.json';
import { HeaderMobile } from './mobile/mobile';
import { NavMenu } from './types';
import { ResponseMenuItem } from '@/src/interfaces/data-contracts';

const menuItems: NavMenu = JSON.parse(JSON.stringify(MenuItemsApi));
type Props = {
  headerItems: ResponseMenuItem[];
  hamburgerItems: ResponseMenuItem[];
};
export const Header: React.FC<Props> = ({ headerItems, hamburgerItems }) => {
  hamburgerItems = hamburgerItems.sort((a, b) => a?.displayOrder! - b?.displayOrder!);
  const headerRef = useRef(null);
  const { isMobile } = useContext(DeviceContext);
  return (
    <header
      ref={headerRef}
      className="sticky top-0 z-50 h-auto w-full border-b border-b-border bg-white"
      data-testid="header"
    >
      {isMobile ? (
        <HeaderMobile headerRef={headerRef} menuItems={hamburgerItems} />
      ) : (
        <HeaderDesktop menuItems={headerItems} />
      )}
    </header>
  );
};
