import classNames from 'classnames';
import { Logo } from '../../icons/icons';
import { NavItem } from './nav-item';
import { NavSubItem } from './nav-item-sub';
import { ResponseMenuItem } from '@/src/interfaces/data-contracts';
import { UserAvatar } from '@/src/features/login/user-avatar';
import Link from 'next/link';

export const HeaderDesktop = (props: { menuItems: ResponseMenuItem[] }) => {
  const menuItems = props.menuItems;

  return (
    <div className="z-50 w-full">
      <div className="container mx-auto ">
        <div className={classNames('navigation-wrap flex flex-nowrap  justify-between')}>
          <Link
            onClick={() => (window.location.href = '/')}
            href="/"
            aria-label="Hangikredi Kobi"
            className="bg-transparent"
            title="Hangikredi Kobi"
          >
            <Logo className={classNames('logo my-4 cursor-pointer')} width={168} height={48} data-testid="logo" />
          </Link>
          <div className={classNames('navbar flex flex-nowrap items-center justify-between')}>
            <ul className="flex h-full gap-8" data-testid="headerMenu">
              {menuItems.map((item: ResponseMenuItem, index: number) => {
                const subMenuItemList = item.subMenuItemList;
                return (
                  <li key={item.id} className="navItem relative" data-testid="menuItem">
                    <NavItem item={item} />
                    {subMenuItemList && subMenuItemList.length > 0 && (
                      <div
                        className={classNames(
                          menuItems.length - 1 === index
                            ? 'left-[-100px] right-[-12px]'
                            : 'left-[-12px] right-[-200px]',
                          'navSubItem absolute top-[80px] z-40 overflow-hidden pt-[2px] drop-shadow-2xl'
                        )}
                      >
                        <nav className="block rounded-lg bg-white" data-testid="nav">
                          <ul
                            className={classNames('subItemList flex flex-col gap-6 p-10')}
                            data-testid="headerSubMenu"
                          >
                            {subMenuItemList.map((subItem: ResponseMenuItem, index) => (
                              <NavSubItem key={subItem.id} subItem={subItem} />
                            ))}
                          </ul>
                        </nav>
                      </div>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="flex items-center">
            <UserAvatar />
          </div>
        </div>
      </div>
    </div>
  );
};
