import classNames from 'classnames';
import { MobileMenuItem } from './item';
import { ResponseMenuItem } from '@/src/interfaces/data-contracts';

export const Menu = (props: { menuItems: ResponseMenuItem[]; openMenu: boolean; setOpenMenu: Function }) => {
  const menuItems = props.menuItems;
  const openMenu = props.openMenu;
  const setOpenMenu = props.setOpenMenu;
  return (
    <ul
      className={classNames(
        openMenu ? 'activedMenu' : 'closedMenu',
        'mobileMenu fixed left-0 z-40 h-full w-full bg-white transition-all duration-300'
      )}
      data-testid="headerMenu"
    >
      {menuItems.map((item: ResponseMenuItem, index: number) => (
        <MobileMenuItem key={item.id} menuItem={item} openMenu={openMenu} setOpenMenu={setOpenMenu} />
      ))}
    </ul>
  );
};
